var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c("h1", { staticClass: "header__title" }, [_vm._v(_vm._s(_vm.htitle))]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "header__cart",
        class: { hasContent: _vm.cartCount > 0 },
        attrs: { id: "cartBox" },
        on: {
          click: function ($event) {
            return _vm.pageTransition("cart")
          },
        },
      },
      [
        _c("img", { attrs: { src: "/resource/icon_cart.svg", alt: "カート" } }),
        _vm._v(" "),
        _vm.cartCount > 0
          ? _c(
              "div",
              { staticClass: "header__number", attrs: { id: "badge" } },
              [_vm._v(_vm._s(_vm.cartCount))]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "header__search",
        on: {
          click: function ($event) {
            return _vm.pageTransition("search")
          },
        },
      },
      [_c("img", { attrs: { src: "/resource/icon_search.svg", alt: "検索" } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }