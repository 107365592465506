var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", [
    _c("ul", { staticClass: "footer__link" }, [
      _c(
        "li",
        {
          class: { current: _vm.activeMenu("index") },
          on: { click: _vm.goHome },
        },
        [
          _c("img", { attrs: { src: "/resource/icon_product.svg", alt: "" } }),
          _vm._v("\n      商品一覧\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { current: _vm.activeMenu("history") },
          on: {
            click: function ($event) {
              return _vm.pageTransition("history")
            },
          },
        },
        [
          _c("img", { attrs: { src: "/resource/icon_calendar.svg", alt: "" } }),
          _vm._v("\n      発注履歴\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { current: _vm.activeMenu("settings") },
          on: {
            click: function ($event) {
              return _vm.pageTransition("settings")
            },
          },
        },
        [
          _c("img", { attrs: { src: "/resource/icon_person.svg", alt: "" } }),
          _vm._v("\n      マイリスト\n    "),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }