var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mylist__wrapper" },
    [
      _c("dl", { staticClass: "mylist__set" }, [
        _c("dt", [_vm._v("リスト名：")]),
        _vm._v(" "),
        _c("dd", [
          _c("span", { attrs: { id: "modalOpen" }, on: { click: _vm.popup } }, [
            _vm._v(_vm._s(_vm.list.list_name)),
          ]),
        ]),
        _vm._v(" "),
        _c("dt", [_vm._v("発注点数：")]),
        _vm._v(" "),
        _c("dd", [_vm._v(_vm._s(_vm.list.cart.length) + "点")]),
        _vm._v(" "),
        _c("dt", [_vm._v("作成日時：")]),
        _vm._v(" "),
        _c("dd", [_vm._v(_vm._s(_vm.list?.mylistDateCreated))]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "mylist__delete",
          attrs: { type: "button" },
          on: { click: _vm.deleteMylist },
        },
        [_c("img", { attrs: { src: "/resource/icon_cross.svg", alt: "削除" } })]
      ),
      _vm._v(" "),
      _c("Modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.list.popup,
            expression: "list.popup",
          },
        ],
        attrs: { list: _vm.list },
        on: { popup: _vm.popup, "re-order": _vm.reOrder },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }